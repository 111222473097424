<template>
  <form
  id="app"
  v-on:submit.prevent="Salvar()"
  method="post"
>
  <b-card-code title="">
    <center><b>{{Questionario.descricao}}</b></center>
    <hr>

      <div v-for="dimenssao in Questionario.dimenssao" :key="dimenssao.idformdimensao">
        <br>
        <div v-if="dimenssao.exibedimensao == 'S'">
          <b>{{ dimenssao.descricao }} </b>
        </div>

        <div v-for="enunciado in dimenssao.enunciado" :key="enunciado.idformenunciado">
          <br>
          {{ enunciado.ordenador }}) {{ enunciado.descricao }}
          <br><br>
        
              <div v-if="enunciado.tipoenunciado == 'T'">                
                 <b-form-input
                   v-model="enunciado.resposta"
                   autocomplete="false"
                   placeholder=""
                   :required="enunciado.obrigatorio == 'S' ? true: false "
                 />

              </div>

              <div v-if="enunciado.tipoenunciado == 'O'">                  
                <b-form-textarea
                   v-model="enunciado.resposta"
                   placeholder=""
                   rows="3"
                   :required="enunciado.obrigatorio == 'S' ? true: false "
                 />
              </div>


            <div v-if="enunciado.tipoenunciado == 'C'">                  
              <b-form-select
              v-model="enunciado.resposta"
              :options="CarregaOpcoesAlternativa(enunciado)"
              :required="enunciado.obrigatorio == 'S' ? true: false ">
               </b-form-select>    

               <div v-if="enunciado.aceitaobservacao == 'S'">    
                <br>
                {{enunciado.textoobservacao === '' ? 'Detalhe' : enunciado.textoobservacao}}:            
                <b-form-textarea
                   v-model="enunciado.observacao"
                   placeholder=""
                   rows="3"
                   :required="enunciado.obrigatorio == 'S' ? true: false "
                 />
               </div>

            </div> 
            
            
            <div v-if="enunciado.tipoenunciado == 'U'">
                <div v-if="enunciado.disposicao == 'LINHA'">
                   <label 
                     v-for="alternativa in enunciado.alternativa" :key="alternativa.idformalternativa"
                     > 
                     &nbsp;
                     <input v-if="enunciado.tipoenunciado == 'U'"
                      type="radio"
                      name="alternativa"
                      :id="alternativa"
                      :value="alternativa.idformalternativa.toString()"
                      v-model="enunciado.resposta"
                      :required="enunciado.obrigatorio == 'S' ? true: false "
                      
                    /> 
                     {{ alternativa.descricao }} 
                  </label>  

                </div>
                
                <div v-if="enunciado.disposicao == 'PADRAO'">
                  <div                 
                   v-for="alternativa in enunciado.alternativa" :key="alternativa.idformalternativa"> 
                     
                     <input v-if="enunciado.tipoenunciado == 'U'"
                      type="radio"
                      name="alternativa"
                      :id="alternativa"
                      :value="alternativa.idformalternativa.toString()"
                      v-model="enunciado.resposta"
                      :required="enunciado.obrigatorio == 'S' ? true: false "
                    /> 
                     {{ alternativa.descricao }}                      
                  </div> 
               </div>  

               <div v-if="enunciado.aceitaobservacao == 'S'">    
                <br>
                {{enunciado.textoobservacao === '' ? 'Detalhe' : enunciado.textoobservacao}}:                     
                <b-form-textarea
                   v-model="enunciado.observacao"
                   placeholder=""
                   rows="3"
                   :required="enunciado.obrigatorio == 'S' ? true: false "
                 />
               </div>
             </div>
          
        </div>
      
        <br>
      </div>


      <b-button v-if="Questionario.idformquestionario > 0" 
      variant="success"
      type="submit"
      >
      <span>SALVAR</span>
    </b-button>
     
</b-card-code>
</form>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import apiPortalSky from '@/services/apiPortalSky'
import AlertaMsg from '@/customPortal/AlertaMsg.vue'
import ConexaoAPI from '@/customPortal/ConexaoAPI.vue'
import Spinner from '@/components/Spinner.vue'
import Multiselect from 'vue-multiselect'

import {
  BRow, BFormGroup,
  BTable, BFormSelect, BPagination,
  BCardBody, BFormInput, BCol, BButton, BFormTextarea, BFormRadioGroup
} from 'bootstrap-vue'

export default {
  components: {
    BFormInput,
    BCardCode,
    BFormGroup,
    BRow,
    BTable,
    BFormSelect,
    BPagination,
    BCardBody,
    BCol,
    BButton,
    BFormTextarea,
    Multiselect,
    BFormRadioGroup
  },
  data() {
    return {
      Questionario: {
      },
      IDFORMQUESTIONARIO:'',   
    }
  },
  mounted() {
    if (this.$route.params.id != null) {
      this.IDFORMQUESTIONARIO = this.$route.params.id
    }

    this.Consultar()
    
  },
  methods: {
    async Consultar() {
      Spinner.StarLoad()
      let notification = ''
      const usuario = this.$session.get('usuario')
      const URLpost = `/skQuestionario/MostraQuestionario?CODUSU=${usuario.codusu}&IDFORMQUESTIONARIO=${this.IDFORMQUESTIONARIO}`
      
      await apiPortalSky.post(URLpost, '').then(response => {
        if (response.status === 200) {
          this.Questionario = response.data
        }
      }).catch(error => {
        notification = ConexaoAPI.ValidaErro(error)
        AlertaMsg.FormataMsg(notification)
      })

      if(notification === '') {
       Spinner.EndLoad()
      }
    },
    async Salvar() {
      
      Spinner.StarLoad()
      let notification = ''
      const usuario = this.$session.get('usuario')
      const URLpost = `/skQuestionario/SalvaQuestionarioResposta?CODUSU=${usuario.codusu}`
      
      const ParametroJson = JSON.parse(JSON.stringify(this.Questionario))
      await apiPortalSky.post(URLpost, ParametroJson).then(response => {
        if (response.status === 200) {
          notification = { message: `Sucesso ao Gravar o ${this.Questionario.descricao}`, alert_type: 'success' }
          this.$session.set('notification', notification)
          this.$router.push('../home')
        }
      }).catch(error => {
        notification = ConexaoAPI.ValidaErro(error)
        AlertaMsg.FormataMsg(notification)
      })

      if(notification === '') {
       Spinner.EndLoad()
      }



    },
    CarregaOpcoesAlternativa(enunciado) {
       let Opcoes = []

       enunciado.alternativa.forEach(alternativa => {   
          let at = { text: alternativa.descricao, value: alternativa.idformalternativa }
          Opcoes.push(at)
       })

      return Opcoes
    },

  },
  computed: {
  },
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>